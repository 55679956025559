import Image from '@tiptap/extension-image'
import { mergeAttributes } from '@tiptap/core';

const CustomImage = Image.extend({
    renderHTML({ HTMLAttributes }) {
        const { style } = HTMLAttributes;
        return [
            "figure",
            { style },
            ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
        ];
    }
});

export default CustomImage;