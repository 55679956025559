<template>
    <modal name="editor-iframe-add" :width="modalWidth" :focus-trap="true" :min-height="300" height="auto" :scrollable="true">
        <ValidationObserver ref="formAddIframe">
            <div class="flex flex-col px-5 py-5">
                <div class="mt-4">
                    <BaseInput v-model="link" type="text" field_name="Video Link" rules="required" />
                </div>
                <div class="mt-4 pt-4 border-t border-secondary-300 clearfix">
                    <button class="btn-secondary-outline px-3 py-2 float-left" @click="$modal.hide('editor-iframe-add')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
                    <button class="btn-blue px-3 py-2 float-right disabled:opacity-25" @click="addIframe()" :disabled="link == ''">Add Video<BaseIcon icon="play-circle" class="ml-1" /></button>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
const MODAL_WIDTH = 600;

export default {
    props: {
        editor: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            link: '',
        }
    },

    methods: {

        addIframe() {
            this.$refs.formAddIframe.validate().then(success => {
                if (!success) {
                    return;
                }

                let filtered_link = this.link;

                let videoId = this.getVideoId(this.link);
                if(videoId) filtered_link = `//www.youtube.com/embed/${videoId}`;

                this.editor.chain().focus().setIframe({ src: filtered_link }).run();
                this.$modal.hide('editor-iframe-add');
                this.link = '';
            });
        },

        getVideoId(url) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = url.match(regExp);

            return (match && match[2].length === 11) ? match[2] : null;
        },

    },

    created() {
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
    },
}
</script>