<template>
  <div class="editor" v-if="editor">
    <MenuBar class="editor__header" :editor="editor" />
    <editor-content class="editor__content" :editor="editor" />
    <div class="editor__footer">
      {{ wordsCount }} words
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import CharacterCount from "@tiptap/extension-character-count"
import TextAlign from "@tiptap/extension-text-align";
import Underline from '@tiptap/extension-underline'
import Link from "@tiptap/extension-link";
import Highlight from "@tiptap/extension-highlight";
import Table from '@tiptap/extension-table'
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import CustomImage from './custom_image'
import MenuBar from './menu_bar.vue'
import Iframe from './iframe'

export default {
  components: {
    EditorContent,
    MenuBar
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
      wordsCount: 0,
    }
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  methods: {
    getWordsCount() {
      this.wordsCount = this.editor.storage.characterCount.words();
    },
  },
  created() {
    this.debounceGetWordsCount = _.debounce(() => {
      this.getWordsCount();
    }, 500);
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        CustomImage.configure({
          allowBase64: true,
        }),
        Table,
        TableRow,
        TableHeader,
        TableCell,
        TextAlign.configure({
          types: ['heading', 'paragraph', 'image'],
        }),
        Link.configure({
          openOnClick: false,
        }),
        Highlight,
        Underline,
        CharacterCount,
        Iframe,
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
        this.debounceGetWordsCount();
      },
    });

    this.getWordsCount();
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>