<template>
    <modal name="editor-image-add" :width="modalWidth" :focus-trap="true" :min-height="300" height="auto" :scrollable="true">
        <div class="flex flex-col px-5 py-5">
            <div class="flex flex-wrap border-b border-secondary-300 text-sm">
                <div class="cursor-pointer px-6 py-2 font-semibold" @click="tab = 1; image = null; imageBase64 = null;" v-bind:class="tab == 1 ? 'bg-primary-500 text-white' : 'bg-secondary-300'">Link</div>
                <div class="cursor-pointer px-6 py-2 font-semibold" @click="tab = 2; imageLink = '';" v-bind:class="tab == 2 ? 'bg-primary-500 text-white' : 'bg-secondary-300'">Upload</div>
            </div>
            <div class="mt-4" v-if="tab == 1">
                <BaseInput v-model="imageLink" type="text" field_name="Image Link" rules="required" />
            </div>
            <div class="mt-2 text-xs text-red-500" v-if="tab == 1 && imageLink != '' && !validImage">
                Image URL needs to be valid
            </div>
            <div class="mt-4" v-if="tab == 2">
                <label class="text-gray-600 text-sm font-bold pb-2 font-serif">Image<b class="text-red-500 ml-1">*</b></label>
                <input type="file" accept="image/*" ref="imageCreate" @change="fileChangeCreate()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-3 mt-2 pb-2" />
            </div>
            <div class="mt-4 pt-4 border-t border-secondary-300 clearfix">
                <button class="btn-secondary-outline px-3 py-2 float-left" @click="$modal.hide('editor-image-add')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
                <button class="btn-blue px-3 py-2 float-right disabled:opacity-25" @click="addImage()" :disabled="imageLink == '' && image == null">Add Image<BaseIcon icon="image" class="ml-1" /></button>
            </div>
        </div>
    </modal>
</template>

<script>
const MODAL_WIDTH = 600;

export default {
    props: {
        editor: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            tab: 1,
            imageLink: '',
            image: null,
            imageBase64: null,
        }
    },

    computed: {
        validImage() {
            if(this.imageLink) return this.imageLink.match(/\.(jpeg|jpg|gif|png)$/) != null;
            else return false;
        }
    },

    methods: {

        fileChangeCreate() {        
            if(this.$refs.imageCreate.files.length > 0) {
                this.image = this.$refs.imageCreate.files[0];
                this.imageToBase64();
            }
            else {
                this.image = null;
                this.imageBase64 = null;
            }
        },

        imageToBase64() {
            var vm = this;
            var reader = new FileReader();

            reader.onload = function (event) {
                vm.imageBase64 = event.target.result;
            }
            reader.readAsDataURL(this.image);
        },

        addImage() {
            let src = '';

            if(this.validImage) src = this.imageLink;
            else if(this.image != null) src = this.imageBase64;

            if(src != '') {
                this.editor.chain().focus().setImage({ src: src }).run();
                this.$modal.hide('editor-image-add');

                // reset fields
                this.imageLink = '';
                this.image = null;
                this.imageBase64 = null;
            }
        }

    },

    created() {
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
    },
}
</script>