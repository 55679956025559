<template>
    <modal name="editor-link-add" :width="modalWidth" :focus-trap="true" :min-height="300" height="auto" :scrollable="true">
        <ValidationObserver ref="formAddLink">
            <div class="flex flex-col px-5 py-5">
                <div class="mt-4">
                    <BaseInput v-model="link" type="text" field_name="Link" />
                </div>
                <div class="mt-4 pt-4 border-t border-secondary-300 clearfix">
                    <button class="btn-secondary-outline px-3 py-2 float-left" @click="$modal.hide('editor-link-add')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
                    <button class="btn-blue px-3 py-2 float-right" @click="addLink()">Add Link<BaseIcon icon="link" class="ml-1" /></button>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
const MODAL_WIDTH = 600;

export default {
    props: {
        editor: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            link: '',
        }
    },

    methods: {

        addLink() {
            this.$refs.formAddLink.validate().then(success => {
                if (!success) {
                    return;
                }

                if(this.link == '') {
                    this.editor.chain().focus().unsetLink().run();
                }
                else {
                    this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.link }).run();
                    this.link = '';
                }

                this.$modal.hide('editor-link-add');
            });
        },

        checkPreviousLink() {
            let previousUrl = this.editor.getAttributes('link').href;
            if(previousUrl) this.link = previousUrl;
            else this.link = '';
        },

    },

    created() {
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
    },
}
</script>